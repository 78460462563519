import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import clientone from "../assets/images/client-one.png"
import clienttwo from "../assets/images/client-two.png"
import clientthree from "../assets/images/client-three.png"
import clientfour from "../assets/images/clientfour.png"
import clientfive from "../assets/images/aif.png"
import clientsix from "../assets/images/dreamTn.png"
import clientseven from "../assets/images/kanavu.png"
import clienteight from "../assets/images/maatram.png"
import clientnine from "../assets/images/naandi.png"
import clientten from "../assets/images/rotary.png"
import clientleven from "../assets/images/Tech Mahindra Foundation.png"
import ourstory_imageone from "../assets/images/ourstory_imageone.png"
import ourstoryimagetwo from "../assets/images/ourstoryimagetwo.png"
import { Trans } from 'gatsby-plugin-react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,Autoplay } from 'swiper';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

export default function OurStory() {
    return (
        <Layout>
            <div className="ourstory_sectiononewraper">
                <div className="row ourstory-sectionone-wraped container-max-width">
                    <div className="col-md-12 ourstory-sectionone-wraped-inner">
                        <h1 className="subtitle">
                            <Trans>Our Story</Trans>
                        </h1>
                        <div className="descptionwraper">
                            <Trans>Talking Yak is an English teaching system designed specifically for Hindi and Tamil speakers.With over 400 lessons in each of those languages, and through customizing each learner’s curriculum based on their pre-existing knowledge, Talking Yak has proven to be up to seven times (7x) faster than conventional classroom methods.</Trans>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ourstory-sectiontwo-wraper">
                <div className="ourstory-sectiontwo-wraped row container-max-width">
                    <div className="col-md-6 leftblock-wraper">
                        <div className="descptionwraper">
                            <p><Trans>Instruction is complimented by thousands of all-English exercises, 
                                the world’s most advanced speech recognition / pronunciation engine,
                                personalized mastery queues, and live teachers to help guide
                                learners down the path of English mastery. Our programs are 
                                being used across India.</Trans>
                            </p> 
                            <p><Trans>NGOs, such as Hope Foundation, Barclay’s, Tech Mahindra and Dell, 
                                rely on Talking Yak to rapidly enable beneficiaries to thrive
                                in 100% English speaking environments. Colleges and universities 
                                across India use Talking Yak to increase placements and
                            prepare graduates to excel in today’s highly collaborative
                                work environments. School students realize tremendous 
                                advantages, such as faster reading, perfected grammar, 
                                effortless conversational ability and clear, confident speech.
                                Even state governments harness Talking Yak for teacher 
                                training and have shown as much as 7 level gains in as
                                little as three months of study.</Trans>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 rightblock-wraper">
                        <div className="image-wraper">
                            <img src={ourstory_imageone} alt="ourstory_imageone" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="our_story_clientsectionwraper client-sectionwraper">
                <div className="home-sectionseven-wraped container-max-width client-sectionwraped">
                    <div className="row client-sectionwraped-inner">
                        <div className="col-md-4 descptionwraper">
                            <div className="descptionwraped"><Trans>Trusted by leading multi-national corporations, start-ups & small businesses</Trans></div>
                        </div>
                        <div className="col-md-8 client-items-wraper">
                            <div className="row items-wraper">
                                <Swiper
                                        modules={[Navigation,Autoplay]}
                                        spaceBetween={3}
                                        loop={false}
                                        breakpoints={{
                                            767: {
                                            // width: 576,
                                            slidesPerView: 1,
                                            },
                                            768: {
                                            // width: 768,
                                            slidesPerView: 4,
                                            },
                                        }}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction:false,
                                        }}
                                        navigation={{ clickable: true }}
                                        className="clientsSwiper"
                                        >
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientone} alt="clientone" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientfour} alt="clientfour" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientthree} alt="clienthtree" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clienttwo} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientfive} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientsix} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientseven} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clienteight} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientnine} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientten} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="client-list">
                                                <img src={clientleven} alt="clienttwo" />
                                            </div>
                                        </SwiperSlide>
                                </Swiper>
                            </div>
					    </div>   
                    </div>
                </div>
            </div>
            <div className="our-storysectionfourwraper">
                <div className="our-storysectionfourwraped row">
                    <div className="col-md-12 title-descmain-wraper">
                        <h3 className="subtitle">
                            <Trans>Talking Yak was Manufactured in India</Trans>
                        </h3>
                        <div className="descptionwraper">
                            <p>
                                <Trans>From humble beginnings on Sundaramurthy Road in Bangalore, four talented people,
                                including three former Teach for India fellows, began work on a project to
                                change the way English is taught in India.</Trans>
                            </p>
                            <p>
                                <Trans>Talking Yak embarked on a simple goal, albeit difficult goal: to remove the barriers to learning English by making the
                                most intuitive, effective and accessible system ever created.</Trans>
                            </p>
                            <p>
                                <Trans>
                                Our effort included a deep, systematic dive into curriculum, content, skill assessments
                                and interactive communication. It required a nuanced understanding of the means by
                                which technology can enhance education, and, of course, it required an intimate, 
                                local understanding of our audience, the Indian learner.</Trans>
                            </p>
                            <p>
                                <Trans>Those who contributed weren’t limited to this initial team. Through its history, our company has benefited from the efforts of nearly 100 people, including:</Trans>
                            </p>
                            <p>
                                <Trans>Teachers with expertise in curriculum design, lesson crafting and actionable assessments, writers fluent
                                    in English, Hindi and Tamil, translators and script editors, filmmakers and 
                                    video editors, sound engineers and sound editors, animators and illustrators,
                                        graphic designers and UX/UI experts, actors (including some remarkably funny
                                        comedians and accomplished stage performers), voiceover artists, content producers, statisticians, program managers and a handful of mature and responsible interns. And, of course, bringing our product to life was the role of, unarguably, some of the best 
                                software developers in India.</Trans>
                            </p>
                            <div className="image-wraper">
                                <img src={ourstoryimagetwo} alt="ourstoryimagetwo" />
                            </div>
                            <i><Trans>Talking Yak Office and Conference Facility Today</Trans></i>
                            <div className="descptionwraper">
                                <p><Trans>And, that was just inside our walls.</Trans></p>
                                <p><Trans>We’ve also been greatly assisted from the outside community. We’ve received invaluable inspiration, help and insight from NGO partners across the country and from university and government advisors, foundation leaders and industry giants. We are especially thankful to the schools and colleges that welcomed us into their institutions for research and field testing.</Trans></p>
                                <p><Trans>To all those who contributed, you should proudly know that through our combined efforts, 
      we have changed the way English is being taught in India. We are working wonders.</Trans></p>
                                <p><Trans>On behalf of myself, our organisation and the billions of English language aspirants worldwide, thank you.</Trans></p>
                                <p><Trans>Sincerely,</Trans><br/><Trans>Tom</Trans></p>
                            </div>
                            <hr></hr>
                            <div className="descptionwraper coloured">
                                <Trans>Thomas Latinovich is the founder of Talking Yak. Following a multi-decade career in Silicon Valley, including leadership positions with @Home Network, Excite@Home, Matchlogic and the successful co-founding of Jingle Networks / 800-FREE411, he dedicated nearly 10 years teaching English to South East Asian and Central American adult immigrants in economically disadvantaged areas of Northern California. His expertise is rapidly readying students for employment placement in 100% English speaking environments. In 2015, he brought that understanding to India and founded Talking Yak.</Trans>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
{
	locales: allLocale {
	  edges {
		node {
		  language
		  ns
		  data
		}
	  }
	}
}
`
